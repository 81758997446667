require("./prompt.js.rb")

import "index.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

window.onload = function() {
  var obfuscatedEmailElements = document.querySelectorAll('[data-email]');

  obfuscatedEmailElements.forEach(function(element) {
    var obfuscatedEmail = element.getAttribute('data-email');

    // Subtract 1 from each character's ordinal value to get the original character
    var originalEmail = '';
    for (var i = 0; i < obfuscatedEmail.length; i++) {
      originalEmail += String.fromCharCode(obfuscatedEmail.charCodeAt(i) - 1);
    }

    // Deobfuscate the email
    var deobfuscatedEmail = originalEmail.replace('[at]', '@').replace('[dot]', '.');

    element.textContent = deobfuscatedEmail;
  });
};

console.info("Bridgetown is loaded!")
