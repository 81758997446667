last_seen = local_storage&.seen_reminder
if last_seen && ((Date.now() - last_seen.to_i(10)) > window.time_to_reset)
  delete local_storage.seen_reminder
  delete local_storage.seen_times
end
if local_storage && (!local_storage.seen_reminder || local_storage.seen_times < 3)
  local_storage.seen_reminder = Date.now()
  local_storage.seen_times = local_storage.seen_times ? local_storage.seen_times + 1 : 0
  set_timeout(proc do
    e = document.get_element_by_id("attention")
    e.class_list.add("slide-in-right")
    e.class_list.add("shown")
    set_timeout(proc do
      e = document.get_element_by_id("attention-wrap")
      e.first_element_child.class_list.add("wobble-ver-right")
      e = document.get_element_by_id("attention-text")
      e.class_list.add("shown")
      e.class_list.add("text-focus-in")
    end, 1000)
  end, 1000)
end
